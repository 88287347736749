/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind utilities;

@media only screen and (min-width: 960px) {

  body:not(.os-mac) {

      ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          background-color: rgba(0, 0, 0, 0);
      }

      ::-webkit-scrollbar:hover {
          width: 8px;
          height: 8px;
          background-color: rgba(0, 0, 0, 0.06);
      }

      ::-webkit-scrollbar-thumb {
          border: 2px solid transparent;
          border-radius: 20px;
          box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
      }

      ::-webkit-scrollbar-thumb:active {
          border-radius: 20px;
          box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
      }

      &.dark {
          ::-webkit-scrollbar-thumb {
              box-shadow: inset 0 0 0 20px rgba(255, 255, 255, 0.24);
          }

          ::-webkit-scrollbar-thumb:active {
              box-shadow: inset 0 0 0 20px rgba(255, 255, 255, 0.37);
          }
      }
  }
}
