/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";
@import 'styles/angular-material';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex {
    position: relative;
    display: flex;
    align-items: stretch;
    min-height: 48px;
    border-radius: 6px;
    padding: 0 16px;
    border-width: 1px;
    --tw-border-opacity: 1!important;
    border-color: rgba(203, 213, 225, var(--tw-border-opacity))!important;
}

.mat-form-field-underline {
    display: none;
}

.dash .mat-expansion-panel-body {
    @media screen and (max-width: 800px) {
        .apexcharts-subtitle-text {
            transform: translateY(-50px) !important;
        }
    }
    padding: 0 !important;
}

.options {
    .mat-form-field-wrapper {
        padding: 0 !important;
    }
}

.succes-modal {
    overflow: unset !important;
    max-height: 80vh;
    max-width: 95vw !important;
    mat-dialog-container {
        // border-radius: 25px !important;
        overflow: unset !important;
        padding: 0 !important;

        // div::-webkit-scrollbar-track {
        //     background: transparent !important;
        // }
    }
}
.editar-modal {
  overflow: unset !important;
  mat-dialog-container {
      // border-radius: 25px !important;
      padding: 0 !important;
      max-height: 90vh;
  }
}


.apexcharts-legend-series {
    -webkit-line-clamp: 1 !important;
    -moz-line-clamp: 1 !important;
    // display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -moz-box-orient: vertical !important;
    // overflow: hidden !important;
    padding: 0px 5px !important;
    border-radius: 4px;
    line-height: 3 !important;
    width: 100% !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

// @media screen and (min-width:800) {
.apexcharts-legend {
    display: block !important;
    overflow-y: scroll !important;
}

// }
.apexcharts-legend-series:nth-child(odd) {
    background: #f1f1f2;
}
